<template>
    <div>
      <TextFieldComponent
        v-model="search"
        @focus="menu = true"
        :placeholder="placeholder"
        :rules="rules"
        :disabled="disabled"
      ></TextFieldComponent>
  
      <v-menu
        v-model="menu"
        activator="parent"
        max-height="300"
        offset-y
        transition="scale-transition"
      >
        <div class="list">
          <div
            v-for="sample in filteredAirports"
            :key="sample.iata_code"
            @click="selectSample(sample)"
          >
            <div class="rowItem">
              <div style="display: flex;justify-content: space-between;width: 100%;">
                <div class="sample-name secondRow">{{ sample.name }}</div>
                <v-icon @click="removeItem(sample.id)">mdi-close</v-icon>
              </div>
            </div>
            <v-divider class="border-opacity-50 dividerClass" />
          </div>
        </div>
      </v-menu>
    </div>
  </template>
  
  <script>
  import TextFieldComponent  from "../TextFieldComponent.vue";
  export default {
    components: { TextFieldComponent },
    props: ["items", "selected", "disabled", "rules", "placeholder"],
    data() {
      return {
        search: '',
        menu: false,
        samples: this.items,
      };
    },
    created(){
      if(this.selected){
      this.search=  this.selected
      }
    },
    computed: {
      filteredAirports() {
        
        const searchLower = this.search.toLowerCase(); 
        return this.samples.filter(
          (sample) =>
            sample.name.toLowerCase().includes(searchLower)
        );
      },
    },
    methods: {
      selectSample(sample) {
        this.search = `${sample.name}`;
        this.menu = false;
        this.$emit("selected", sample);
      },
      removeItem(sample) {
        this.$emit("removeItem", sample);
      },
    },
  };
  </script>
  
  <style scoped>
  .sample-code {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f5f5;
    border: 1px solid #d6d7db;
    border-radius: 8px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #333;
  }
  
  .list {
    background-color: #fff;
    max-height: 400px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    overflow: auto;
    display: grid;
    align-items: center;
  }
  
  .rowItem {
    width: 100%;
    display: flex;
    gap: 15px;
    padding: 8px 12px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .rowItem:hover {
    background-color: #f8f4f4;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .firstRow {
    font-size: 16px;
    line-height: 1.4;
  }
  
  .secondRow {
    width: 100%;
    font-size: 14px;
    line-height: 1.4;
    color: #5e616e;
  }
  
  .sample-name {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .dividerClass {
    border-color: #d6d7db;
    width: 90%;
    position: relative;
    left: 5%;
  }
  </style>
  